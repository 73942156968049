<template>
  <b-card class="card-congratulation-medal">
    <div v-if="loading_google === true" class="mt-3 ml-3">
        <b-spinner 
            variant="primary"
            style="width: 3rem; height: 3rem;"
            label="loading"
        />
    </div>
    <div v-else>
      <div v-if="errore_caricamento === false">
        <div v-if="api_token_google.token_last_15_caratters != ''">
            <b-card-text class="font-small-3">
            Token Api <feather-icon icon="InfoIcon" size="14" class="text-primary" v-b-tooltip.hover.top="api_token_google.token_last_15_caratters" /> attivo per
            
            </b-card-text>
            <b-card-text class="font-small-3">
                <ul class="pl-2">
                    <li v-for="service in api_token_google.services">{{ service.name }}</li>        
                </ul>
            </b-card-text>
            <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-danger"
            @click="logout(id_utente)"
            >
            Esci da Google Workspace
            </b-button>
        </div>
        <div v-else>
            <b-card-text class="font-small-3">
            Non puoi accedere<br />ai servizi di Google Workspace.
            </b-card-text>
            <b-card-text class="font-small-3">
            Prego effetua login
            </b-card-text>
            <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            :href="api_token_google.url_login"
            target="_self"
            >
            Accedi a Google Workspace
            </b-button>

        </div>
      </div>
      
      <div v-else>
        <h6 class="mb-75 mt-2 text-danger">
          Errore inatteso dalla nostra API.<br />prego segnalare l'errore al reparto tecnico, grazie
        </h6>
      </div>            
    </div>

    <b-img
      :src="require('@/assets/images/logo-partners/google_workspace.png')"
      class="congratulation-medal"
      style="right: 17px;"
      alt="Google Workspace"
    />
  </b-card>
</template>

<script>
import {
  BCard, BCardText, BButton, BImg, BSpinner, VBTooltip
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BCardText,
    BImg,
    BButton,
    BSpinner,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    id_utente: '',
    api_token_google: [],
    errore_caricamento: '',
    loading_google: true,
  },
  methods: {
    logout(id_utente){
      this.$swal({
        title: 'Sei sicuro di voler procedere?',
        text: "confermi che vuoi fare logout dal tuo account Google Workspace?",
        icon: 'error',
        showCancelButton: true,
        confirmButtonText: 'SI, Procedi',
        cancelButtonText: 'Annulla operazione',
        customClass: {
          confirmButton: 'btn btn-outline-danger',
          cancelButton: 'btn btn-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {

            this.$http.get('api/crm/google/oauth/logout/'+id_utente)
            .then(response => { 
                if(response.data.statusCode===200){
                  //risposta positiva
                  this.$swal({
                    icon: 'success',
                    title: response.data.reply.dati.messaggio,
                    //text: 'operazione effettuata correttamente',
                    confirmButtonText: 'chiudi',
                    customClass: {
                    confirmButton: 'btn btn-success',
                    },
                  })

                  console.log(response.data.reply.dati.api_token_google)
                  //aggiorna dati visualizzati (svuotanto tutto così da forzare la visualizzazione del login)
                  this.$emit('update:api_token_google', response.data.reply.dati.api_token_google)
                  
                  
                } else {
                //risposta negativa (errore sul server)
                  this.$swal({
                      icon: 'error',
                      title: 'Si è verificato un errore',
                      text: 'operazione non riuscita, ti preghiamo di contattate l\'assistenza tecnica per segnalare il problema',
                      customClass: {
                      confirmButton: 'btn btn-outline-primary',
                      },
                  })
                }
            }).catch(e => {
                console.log(e);
            })
        }
      })
    },
  }
}
</script>
